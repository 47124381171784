import type { HeadersFunction } from "~/remix";
import { Outlet } from "~/remix";

import type { PropsWithChildren } from "react";
import React from "react";
import ThemeSwitcher from "~/components/Theme/ThemeSwitcher";
import type { PropsWithClassName } from "~/types/react";

export default function BaseLayout({
  children = <Outlet />,
  className = "?",
  themeToggler = (className = '?') => <ThemeSwitcher className={className.replace('?', "absolute -top-2 -right-2  z-[1]")} />,
  themeTogglerClassName = '?'

}: PropsWithClassName<PropsWithChildren<{
  themeToggler?: (className?: string) => React.ReactNode
  themeTogglerClassName?: string
}>>) {
  return (
    <>
      <main className="relative bg-base-100 flex items-center justify-center flex-grow">
        <div className="relative pb-16 pt-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {themeToggler(themeTogglerClassName)}
            <div className={className.replace('?', 'relative shadow-xl overflow-hidden rounded-2xl bg-base-300')}>
              <div className="relative px-4">
                <div
                  className="mx-auto my-10 max-w-sm sm:flex sm:max-w-none sm:justify-center"
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
